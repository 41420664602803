import React from 'react';
import FormComponent from './components/FormComponent';
import './styles/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  return (
    <>
      <FormComponent />
    </>
  );
};

export default App;
